import mockup11 from "../assets/img/dashboard-rounded-corner-screen-shoot-1000.png";
import "../fonts/royce.css"
function Integration() {
    return (
        <div className="integration-section ui-gradient-blue laptop-showcase-left">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-lg-8">
                        <img data-aos="fade-right" data-aos-delay="500" className="img-fluid laptop-left" src={mockup11} alt="Shafa-EHR Landing" />
                    </div>
                    <div className="col-md-5 col-lg-4 mt-5" data-vertical_center="true">
                        <div data-aos="fade-up" data-aos-delay="500">
                            <h2 className="heading text-white" style={{ textAlign: "center" }}>
                                <span style={{ fontFamily: "Royce-Medium" }}>shafa-EHR</span>, designed to meet your needs.
                            </h2>
                            <br />
                            <h6 className="heading text-dark-gray" style={{ textAlign: "center" }}>
                                The most physician-friendly EHR Developed by Physicians for Physicians.
                            </h6>
                            <br />
                            <ul className="ui-checklist mt-2" style={{ textAlign: "left" }}>
                                <li>
                                    <h6 className="heading text-dark-gray">Modernizing your healthcare delivery</h6>
                                </li>
                                <li>
                                    <h6 className="heading text-dark-gray">Ensure your patients receive the best possible care</h6>
                                </li>
                                <li>
                                    <h6 className="heading text-dark-gray">Designed to improve your productivity and boost your practice</h6>
                                </li>
                            </ul>
                            <div className="text-center">  <a target="_blank" href="https://emr.shafa.care/registration" className="btn start-free ">Start Free</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Integration