import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Landing from "../pages/landing";


const AppRoutes = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/*" element={<Landing />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
