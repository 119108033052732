
function Features() {
    return (
        <div className="features-section pt-6">
            <div className="container ui-icon-blocks ui-blocks-h icons-lg">
                <div className="section-heading text-center">
                    <h2 className="heading text-dark-gray">
                        Awesome Features
                    </h2>
                    <p className="paragraph">
                        The most physician-friendly and comprehensive EHR (Electronic Health Record) system. Developed by Physicians for Physicians..
                    </p>
                </div>

                <div className="row mt-5 text-center" >
                    <div className="col-md-4 ui-icon-block ">
                        <div className=" features-card mx-1" data-aos="fade-up" data-aos-delay="300">
                            <div className="icon icon-lg icon-circle icon-cloud-download text-red"></div>
                            <h4 className="text-dark-gray">Intelligent Cloud</h4>
                            <p>
                                Ready-to-use, cloud-based digital health tools designed to power your practice in addressing social needs and advancing health equity.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 ui-icon-block">
                        <div className=" features-card mx-1" data-aos="fade-up" data-aos-delay="300">
                            <div className="icon icon-lg icon-circle icon-settings text-red"></div>
                            <h4 className="text-dark-gray">Full Customization</h4>
                            <p>
                                Built-in and fully customizable templates can help you meet your EHR needs. Customize templates based on your practice type to avoid data re-entry.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 ui-icon-block">
                        <div className=" features-card mx-1" data-aos="fade-up" data-aos-delay="300">
                            <div className="icon icon-lg icon-circle icon-people text-red"></div>
                            <h4 className="text-dark-gray">Patient Engagement</h4>
                            <p >
                                Keep your patients informed and involved with Regular SMS and email campaigns, Digital Prescription link ,Appointment reminders
                            </p>
                        </div>
                    </div>
                </div>
                <small id="product"></small>
            </div>
        </div>
    )
}

export default Features