import React from 'react'

import "../fonts/royce.css"
import "../assets/adminlte.min.css"

function WebSiteNav() {

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    return (
        <nav id="nav-bar-1" className={"navbar navbar-fixed-top transparent  bg-dark-gray"}>
            <div className={"container"}>

                {/*<!-- Navbar Logo -->*/}
                <a className={"ui-variable-logo navbar-brand"} onClick={() => { scrollToSection('emr-intro') }} title="Shafa-EHR Landing"
                    style={{ fontFamily: "Royce-Medium", cursor: "pointer" }} data-scrollto="emr-intro">
                    shafa-EHR
                    {/*<!-- Default Logo -->*/}
                    {/*<img className={"logo-default"} src={logo} alt="Shafa-EHR Landing" data-uhd/>*/}
                    {/*/!*<!-- Transparent Logo -->*!/*/}
                    {/*<img className={"logo-transparent"} src={logo} alt="Shafa-EHR Landing" data-uhd/>*/}
                </a>

                {/*<!-- Navbar Navigation -->*/}
                <div className={"ui-navigation navbar-center"}>
                    <ul className={"nav navbar-nav"}>
                        <li style={{ cursor: "pointer" }} onClick={() => { scrollToSection('features') }}>
                            <a data-scrollto="features">Features</a>
                        </li>
                        <li style={{ cursor: "pointer" }} onClick={() => { scrollToSection('product') }}>
                            <a data-scrollto="">Product</a>
                        </li>

                        <li className="">
                            <a target="_blank" href="https://emr.shafa.care/login">Login</a>
                        </li>
                    </ul>
                </div>

                <a target="_blank" href="https://emr.shafa.care/registration" className="btn btn-sm ui-gradient-peach pull-right start-free">Get Started</a>

                <a href="#" className={"ui-mobile-nav-toggle pull-right"} />

            </div>
        </nav>
    )
}

export default WebSiteNav