import React from "react";
import "../fonts/royce.css"
function WebSiteFooter() {

    return (
        <div className="footer-section">
            <footer className="ui-footer bg-gray py-3">
                <div className="container pt-6 pb-6">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="heading text-dark-gray">
                                <span style={{ fontFamily: "Royce-Medium" }}>shafa-EHR</span>
                            </h2>
                            <p className="mt-1">
                                The Future of Digital Health<br />
                                The most physician-friendly EHR<br />
                                Developed by Physicians for Physicians.
                            </p>
                            <p>
                                &copy; 2022 <a style={{ textDecoration: "none", color: "#5EC1FF", fontWeight: "700" }} href="#" target="_blank" title="Shafa Care">Shafa Care Ltd</a>
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="footer-copyright bg-dark-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 center-on-sm">
                                <p>
                                    &copy; 2017 <a href="#" target="_blank" title="Shafa Care">Shafa Care Ltd</a>
                                </p>
                            </div>
                            <div className="col-sm-6 text-right">
                                <ul className="footer-nav">
                                    <li>
                                        <a href="#">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Terms &amp; Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            FAQ
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

            </footer>
        </div>

    )

}

export default WebSiteFooter