
import widget1 from "../assets/img/file-upload-widget.png"
import widget2 from "../assets/img/analytics-widget.png"

function ShowcaseTwo() {
    return (
        <div className="section bg-light pb-5" style={{paddingTop:"100px"}}>
            <div className="container">
                <div className="row text-center g-0">
                    <div className="col-md-6 pb-5 px-3" data-aos="fade-right" data-aos-delay="500"> 
                        <img className="img-fluid" style={{padding:"0px 30px"}} src={widget1} alt="Shafa-EHR Landing" />
                        <h5 className="text-dark-gray">All Medical Records Stored in the Cloud</h5>
                        <p className="mb-0">
                            All the documents, or files are stored in a cloud-based storage system. Allow you access medical records from anywhere.
                        </p>
                    </div>
                    <div className="col-md-6 pb-5 px-5" data-aos="fade-up" data-aos-delay="500">
                        <img className="img-fluid" src={widget2} alt="Shafa-EHR Landing" />
                        <h5 className="text-dark-gray">Smart Practice, Smart Growth</h5>
                        <p className="mb-0">
                            Adopting efficient and modern methods, such as EHR, to achieve operational efficiency, enhance patient care, and strategically grow a medical practice.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowcaseTwo