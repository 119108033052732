
import support from "../assets/img/support/applify-support.png"

function Support() {
    return (
        <div id="support" className="support-section bg-dark-gray">
            <div className="container">
                <div className="row">
                    {/*<!-- Text Column -->*/}
                    <div className="col-lg-6 col-md-5 my-auto " data-vertical_center="true">
                        {/*<!-- Section Heading -->*/}
                        <div className="section-heading mb-0" data-aos="fade-right" data-aos-delay="500">
                            <h2 className="heading">
                                24/7 Support
                            </h2>
                            <p className="paragraph pb-4">
                                24/7 support tailored for doctors. Around-the-clock assistance for seamless practice operations. Your success is our priority.
                            </p>
                            <div className="row ui-icon-blocks ui-blocks-h icons-md mt-2">
                                <div className="col-4 ui-icon-block animate text-left mb-0 center-on-md" data-show="fade-in-up">
                                    <div className="icon icon-phone"></div>
                                    <h5 className="heading mb-1">Call Support</h5>
                                    <p>
                                        During office hour.
                                    </p>
                                </div>
                                <div className="col-4 ui-icon-block animate text-left mb-0 center-on-md" data-show="fade-in-up">
                                    <div className="icon icon-trophy"></div>
                                    <h5 className="heading mb-1">Email/Chat</h5>
                                    <p>
                                        After office hours.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- Image Column -->*/}
                    <div className="col-lg-6 col-md-7 img-block animate" data-show="fade-in-left" data-vertical_center="true">
                        <img data-aos="fade-up" data-aos-delay="500" src={support} alt="Shafa-EHR Landing img-fluid" className="img-fluid" data-max_width="460" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support