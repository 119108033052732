import WebSiteNav from "../components/nav-bar";
import './landing.scss'
import HeroSection from "../components/hero";
import Features from "../components/features";
import Integration from "../components/integration";
import ShowcaseOne from "../components/showcase-one";
import ShowcaseTwo from "../components/showcase-two";
import Footer from "../components/footer";
import Support from "../components/support";
import { useEffect, useMemo, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const Landing = () => {

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const currentPosition = window.scrollY || window.pageYOffset;
        setScrollPosition(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useMemo(() => {
        let id = document.getElementById('nav-bar-1')
        if (scrollPosition > 50) {
            if (id?.classList?.contains('navbar-dark'))
                return
            id?.classList.add("navbar-dark")
            id?.classList.remove("transparent")
        }
        else if (scrollPosition <= 51) {
            if (id?.classList?.contains('transparent'))
                return
            id?.classList.add("transparent")
            id?.classList.remove("navbar-dark")
        }
    }, [scrollPosition])

    return (
        <>
            {<WebSiteNav />}
            {<div className="main" role="main">
                <HeroSection />
                <Features />
                <Integration />
                <ShowcaseOne />
                <ShowcaseTwo />
                <Support />
                {/* <Testimonial /> */}
                <Footer />
            </div>}
        </>
    );
}

export default Landing;