import React from 'react'
import heroImage from "../assets/img/mockups/visit-mockups-imac-screen-only-1200.png"

function HeroSection() {

    return ([
        <div key={"hero1"} id="emr-intro" className="hero-section  text-center">
            <div className="top-section " >
                <h1 className="title my-auto" data-show="fade-in-up-big" data-delay="100">
                    The <span style={{ color: "#FE6B90" }} >easiest</span> &amp; most <span style={{ color: "#FE6B90" }}>secure</span> EHR
                </h1>
            </div>
            <div style={{ width: "100%" }}>
                <div key={"hero2"} className="section  pt-0 mx-4 banner-img-section" >
                    <img data-aos="fade-up" data-aos-delay="300" src={heroImage} data-max_width="800" className="img-fluid"
                        alt="Shafa-EHR Landing" />
                </div>
            </div>
            <small id="features"></small>
        </div>

    ])

}

export default HeroSection