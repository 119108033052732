import mockup from "../assets/img/laptop-mockup-1000.png"
import "../fonts/royce.css"
function ShowcaseOne() {
    return (
        <div className="show-case-section laptop-showcase">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-lg-4 pt-3 mb-4" data-vertical_center="true" data-aos="fade-right" data-aos-delay="500">
                        <div>
                            <h2 className="heading text-dark-gray">
                                <span style={{ fontFamily: "Royce-Medium" }}>shafa-EHR</span> Comprehensive EHR
                            </h2>
                            <br />
                            <h5 className="heading text-dark-gray">
                                See how the most physician-friendly <span style={{ fontFamily: "Royce-Medium" }}>shafa-EHR</span> can help you increase your efficiency and productivity, reduce costs and tedious paperwork, and improve outcomes.
                            </h5>
                            <br />
                            <div className="text-center">
                                <a target="_blank" href="https://emr.shafa.care/registration" className="btn ui-gradient-peach start-free">Start now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-8">
                        <img data-aos="fade-up" data-aos-delay="500" className="img-fluid laptop" src={mockup} alt="Shafa-EHR Landing" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ShowcaseOne